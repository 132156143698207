<template>
  <div>
      <Banner/>
  </div>
</template>

<script>
import Banner from './components/Banner'
export default {
  components: {
    Banner,
  },
  data() {
    return {
    }
  },
}
</script>
