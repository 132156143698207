<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Adicionar Banner</h4>

          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'banners' })"
            >
              <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
              Listar Banners
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3" v-if="imgs == null || imgs.length <= 0">
                <label>Banner</label>
                <label class="w-100 cursor-pointer">
                  <div class="uploadimg w-100 border p-5 text-center">
                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                    <h6 class="text-muted">clique para enviar o banner</h6>
                  </div>
                  <input
                    type="file"
                    @change="onFileChange"
                    name="image[]"
                    class="d-none"
                  />
                </label>
              </div>
              <div class="col-12" v-if="imgs.length > 0">
                <span v-for="(i, index) in imgs" :key="index">
                  <b-img
                    width="100%"
                    class="img-fluid w-100"
                    :src="i.url"
                    alt="banner"
                  ></b-img>
                  <b-button
                    variant="danger"
                    class="w-100 my-3"
                    @click.prevent="removerImagem(index)"
                  >
                    <i class="bx bx-trash font-size-16 align-middle"></i>
                  </b-button>
                </span>
              </div>
              <div class="col-12 mb-3">
                <label>Status</label>
                <multiselect
                  :options="options_status"
                  v-model="form.status"
                  class="helo"
                  label="text"
                  placeholder="Status"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-12 mb-3">
                <label>Local do Banner</label>
                <multiselect
                  v-model="form.position"
                  :options="options_position"
                  class="helo"
                  placeholder="Local do Banner"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-12">
                <label>Ordem</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Ordem"
                    for="number"
                    type="number"
                    min="0"
                    v-model="form.ordem"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-base">
                <label>
                  Título
                </label>
                <b-form-group>
                  <b-form-input
                    placeholder="Título do banner"
                    for="text"
                    v-model="form.title"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 mb-base">
                <label>Subtítulo</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Subtítulo do banner"
                    for="text"
                    v-model="form.sub_title"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 mb-base">
                <b-form-group>
                  <label>Descrição</label>
                  <b-form-textarea
                    placeholder="Descrição"
                    for="text"
                    v-model="form.description"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <div class="col-12 my-2 border-top pt-3">
                <h5>Link do Banner</h5>
              </div>
              <div class="col-sm-12 col-md-6 mb-2">
                <label>Tipo</label>
                <multiselect
                  :options="options_tipo"
                  class="helo"
                  v-model="form.type"
                  placeholder="Tipo"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-6 mb-2">
                <label>{{ form.type }}</label>
                <multiselect
                  v-model="category"
                  :options="options_categorys"
                  class="helo"
                  label="name"
                  placeholder="Categoria"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoading"
                  @search-change="searchCategory"
                  v-if="form.type == 'Categoria'"
                ></multiselect>
                <multiselect
                  v-else-if="form.type == 'Página'"
                  v-model="page"
                  :options="options_pages"
                  class="helo"
                  label="title"
                  placeholder="Página"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoadingPage"
                  @search-change="searchPage"
                ></multiselect>
                <multiselect
                  v-else-if="form.type == 'Produto'"
                  v-model="product"
                  :options="options_products"
                  class="helo"
                  label="name"
                  placeholder="Produto"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoadingProduct"
                  @search-change="searchProduct"
                ></multiselect>
                <b-form-group v-else-if="form.type == 'Produtos Destaque'">
                  <b-form-input
                    placeholder="Link"
                    class="bg-light"
                    for="text"
                    disabled
                    value="Produtos Destaque"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-else-if="form.type == 'Produtos'">
                  <b-form-input
                    placeholder="Link"
                    class="bg-light"
                    for="text"
                    disabled
                    value="Produtos"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-else>
                  <b-form-input
                    placeholder="Link"
                    for="text"
                    v-model="form.link"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-12 mb-base">
                <label>Nome do Botão</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Nome do Botão"
                    for="text"
                    v-model="form.link_name"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12 mt-3 text-right">
                <b-button variant="success" @click.prevent="salvarDados()">
                  <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                  Salvar
                </b-button>
                <b-button
                  variant="danger"
                  class="ml-2"
                  @click.prevent="$router.push({ name: 'banners' })"
                >
                  <i class="bx bx-x font-size-16 align-middle mr-2"></i>
                  Cancelar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
export default {
  props: {
    edit: {
      type: Object,
      default: function () {
        return null
      },
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      imgs: [],
      options_status: [
        { value: 0, text: 'Ativa' },
        { value: 1, text: 'Bloqueada' },
      ],
      options_position: ['Página Inicial', 'Páginas'],
      options_tipo: [
        'Link',
        'Página',
        'Categoria',
        'Produto',
        'Produtos',
        'Produtos Destaque',
      ],
      form: {
        position: 'Página Inicial',
        type: 'Link',
        status: { value: 0, text: 'Ativa' },
        title: '',
        sub_title: '',
        description: '',
        ordem: 0,
        link: '',
        link_name: '',
      },
      options_pages: [],
      options_categorys: [],
      options_products: [],
      isLoading: true,
      isLoadingPage: true,
      isLoadingProduct: true,
      filtro: {
        status: 0,
        limit: 10,
      },
      filtroPage: {
        status: 0,
        limit: 10,
      },
      filtroProduct: {
        status: 0,
        limit: 10,
      },
      category: null,
      page: null,
      product: null,
    }
  },
  watch: {
    'form.type': function () {
      if (this.form.type == 'Categoria') {
        this.listCategory()
      }
      if (this.form.type == 'Página') {
        this.listPage()
      }
      if (this.form.type == 'Produto') {
        this.listProduct()
      }
    },
  },
  mounted() {
    if (this.edit != null) {
      this.form = this.edit
      this.page = this.edit.page
      this.category = this.edit.category
      this.product = this.edit.product
      this.form.status = {
        value: this.edit.status,
        text: this.edit.status == 0 ? 'Ativo' : 'Bloqueado',
      }
      if (this.edit.image != null) {
        this.edit.image.forEach((element) => {
          var g = {
            file: null,
            url: element.image300,
            id: element.id,
          }
          this.imgs.push(g)
        })
      }
    }
  },
  methods: {
    ...mapActions('banner', [
      'ActionBannerAll',
      'ActionBannerCreate',
      'ActionBannerUpdate',
      'ActionBannerUpdateImage',
      'ActionBannerDeleteImage',
    ]),
    ...mapActions('category', ['ActionCategoryAll']),
    ...mapActions('page', ['ActionPageAll']),
    ...mapActions('product', ['ActionProductAll']),
    salvarDados() {
      if (this.imgs == null || this.imgs.length <= 0) {
        this.$swal({
          title: 'Banner',
          text: 'Você precisa enviar a imagem do banner!',
          icon: 'info',
        })
      } else {
        this.submitBanner()
      }
    },
    async submitBanner() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })

        this.form.status = this.form.status.value
        let data = new FormData()
        if (this.imgs.length > 0) {
          this.imgs.forEach((element) => {
            if (element.id == null) {
              data.append('image[]', element.file)
            }
          })
        }
        data.append('title', this.form.title)
        data.append('sub_title', this.form.sub_title)
        data.append('status', this.form.status)
        data.append('position', this.form.position)
        data.append('ordem', this.form.ordem)
        data.append('description', this.form.description)
        data.append('type', this.form.type)

        if (this.category != null && this.form.type == 'Categoria') {
          data.append('category_id', this.category.id)
        }

        if (this.page != null && this.form.type == 'Página') {
          data.append('page_id', this.page.id)
        }

        if (this.product != null && this.form.type == 'Produto') {
          data.append('product_id', this.product.id)
        }

        data.append('link', this.form.link)
        data.append('link_name', this.form.link_name)

        if (this.edit != null) {
          data.append('id', this.form.id)
          var dados = {
            data: data,
            id: this.form.id,
          }
          await this.ActionBannerUpdate(dados)
        } else {
          await this.ActionBannerCreate(data)
        }
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'banners' })
        })
      } catch (e) {
        console.log(e)
        this.$swal({
          title: 'Erro!',
          text: e.body.message
            ? e.body.message
            : 'Não foi possível realizar a ação!',
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    onFileChange(e) {
      const file = e.target.files[0]
      var g = {
        file: file,
        url: URL.createObjectURL(file),
        id: null,
      }
      this.imgs.push(g)
    },
    removerImagem(e) {
      var img = this.imgs[e]
      this.imgs.splice(e, 1)
      if (img.id != null) {
        this.ActionBannerDeleteImage(img.id).then((_) => {
          if (this.imgs[0].id != null) {
            var fg = {
              id: this.imgs[0].id,
              data: this.imgs[0],
            }
            this.ActionBannerUpdateImage(fg)
          }
        })
      }
    },
    async listCategory() {
      try {
        this.isLoading = true
        await this.ActionCategoryAll(this.filtro).then((res) => {
          this.options_categorys = res.data.data.list
          this.isLoading = false
        })
      } catch (_) {
        this.options_categorys = []
      }
    },
    searchCategory(e) {
      this.filtro.search = e
      this.listCategory()
    },
    async listPage() {
      try {
        this.isLoadingPage = true
        await this.ActionPageAll(this.filtroPage).then((res) => {
          this.options_pages = res.data.data.list
          this.isLoadingPage = false
        })
      } catch (_) {
        this.options_pages = []
      }
    },
    searchPage(e) {
      this.filtroPage.title = e
      this.listPage()
    },
    async listProduct() {
      try {
        this.isLoading = true
        await this.ActionProductAll(this.filtroProduct).then((res) => {
          this.options_products = res.data.data.list
          this.isLoading = false
        })
      } catch (_) {
        this.options_products = []
      }
    },
    searchProduct(e) {
      this.filtroProduct.search = e
      this.listProduct()
    },
  },
}
</script>
